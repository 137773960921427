module.exports={
  "name": "pixi.js",
  "version": "3.0.8",
  "description": "Pixi.js is a fast lightweight 2D library that works across all devices.",
  "author": "Mat Groves",
  "contributors": [
    "Chad Engler <chad@pantherdev.com>",
    "Richard Davey <rdavey@gmail.com>"
  ],
  "main": "./src/index.js",
  "homepage": "http://goodboydigital.com/",
  "bugs": "https://github.com/pixijs/pixi.js/issues",
  "license": "MIT",
  "repository": {
    "type": "git",
    "url": "https://github.com/pixijs/pixi.js.git"
  },
  "scripts": {
    "start": "gulp && gulp watch",
    "test": "gulp && testem ci",
    "build": "gulp",
    "docs": "jsdoc -c ./gulp/util/jsdoc.conf.json -R README.md"
  },
  "files": [
    "bin/",
    "src/",
    "CONTRIBUTING.md",
    "LICENSE",
    "package.json",
    "README.md"
  ],
  "dependencies": {
    "async": "^1.4.2",
    "brfs": "^1.4.1",
    "earcut": "^2.0.2",
    "eventemitter3": "^1.1.1",
    "gulp-header": "^1.7.1",
    "object-assign": "^4.0.1",
    "resource-loader": "^1.6.2"
  },
  "devDependencies": {
    "browserify": "^11.1.0",
    "chai": "^3.2.0",
    "del": "^2.0.2",
    "gulp": "^3.9.0",
    "gulp-cached": "^1.1.0",
    "gulp-concat": "^2.6.0",
    "gulp-debug": "^2.1.0",
    "gulp-jshint": "^1.11.2",
    "gulp-mirror": "^0.4.0",
    "gulp-plumber": "^1.0.1",
    "gulp-rename": "^1.2.2",
    "gulp-sourcemaps": "^1.5.2",
    "gulp-uglify": "^1.4.1",
    "gulp-util": "^3.0.6",
    "jaguarjs-jsdoc": "git+https://github.com/davidshimjs/jaguarjs-jsdoc.git",
    "jsdoc": "^3.3.2",
    "jshint-summary": "^0.4.0",
    "minimist": "^1.2.0",
    "mocha": "^2.3.2",
    "require-dir": "^0.3.0",
    "run-sequence": "^1.1.2",
    "testem": "^0.9.4",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "^1.1.0",
    "watchify": "^3.4.0"
  },
  "browserify": {
    "transform": [
      "brfs"
    ]
  }
}
